.background {
  margin-top: 0px;
}

.titling {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  background-color: white;
}

.title {
  margin-top: 10px;
  font-size: 8rem;
}

.subtitle {
  margin-top: -80px;
}

.slideshowContainer {
  width: 500px;
  height: 500px;
  border-radius: 5%;
  overflow: hidden;
}

.slideshow {
  width: 500px;
}

.paragraphContainer {
  background-color: white;
  margin: 10%;
}

.paragraphHeader {
  font-size: 3rem;
}

.text {
  line-height: 2;
  font-size: 1.5rem;
}

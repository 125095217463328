.container {
  background-color: rgb(46, 46, 46);
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.iconContainer {
  text-align: center;
  margin-right: 30px;
  margin-left: 30px;
  width: 80%;
}

#twitterIcon {
  width: 75px;
}
#discordIcon {
  width: 75px;
}
#DEIcon {
  width: 75px;
  margin-top: 22px;
}

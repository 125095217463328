.navContainer {
  background-color: rgb(0, 9, 59);
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.navObject {
  margin-top: 100px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 100px);
  align-content: center;
}

.buttonContainer {
  z-index: 1;
  background-color: rgb(255, 255, 255);
  height: 75px;
  margin: 10px;
  text-align: center;
  border-radius: 20px;
}

.dropdownContainer {
  grid-row-start: 2;
  grid-column-start: 2;
  width: 1fr;
  height: 75px;
  display: none;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: -10px;
  background-color: rgb(144, 145, 255);
  border-radius: 20px;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .dropdownContainer {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.link {
  background-color: blue;
  text-decoration: none;
  height: inherit;
}

.linkText {
  color: rgb(46, 46, 46);
  font-size: 3rem;
  margin-top: 5px;
  height: inherit;
}

#dropdownText {
  height: 75px;
  line-height: 60px;
  text-align: center;
  color: white;
  font-size: 2rem;
  height: inherit;
}

.buttonContainer:hover {
  background: white;
}

.dropdownContainer:hover > .linkText {
  color: black;
}

.buttonContainer:hover + .dropdownContainer {
  display: block;
  background-color: rgb(0, 9, 59);
  border: white 2px solid;
}

.dropdownContainer:hover {
  display: block;
  background-color: white;
  border: black 2px solid;
}

.buttonContainer:hover > .link > .linkText {
  color: black;
}

.dropdownContainer:hover > .link > #dropdownText {
  color: black;
}

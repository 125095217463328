.page {
  background-color: rgb(0, 9, 59);
  text-align: center;
}
.bannerPhoto {
  height: 100px;
  width: auto;
}
.spacer {
  background-image: url("../assets/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  height: 100px;
}

.galleryContainer {
  display: grid;
  background-color: rgb(0, 9, 59);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2vw;
  padding: 20px;
  place-items: center;
}

.photoContainer {
  background-color: rgb(255, 255, 255);
  width: 200px;
  height: 250px;
  border-radius: 10%;
  overflow: hidden;
  text-align: center;
}

.photo {
  border-radius: 10%;
  width: 200px;
  height: 200px;
}

.statsText {
  margin-top: 10px;
}

.sectionTitle {
  margin: 0;
  text-align: center;
  padding: 10px;
  font-size: 2rem;
  background: rgb(0, 9, 59);
  border-radius: 200px;
  color: white;

  height: 40px;
}

#tooBig {
  font-size: 1rem;
}
